import React from 'react';

export const SpeedControl: React.FC<any> = ({ handleSpeedChange, currentSpeed, isStaticPanel}) => {
  const speedArray = [0.25, 0.5, 0.75, 1, 3, 5];
  return (
    <select onChange={handleSpeedChange} value={currentSpeed.toString()} 
      className={`text-white focus:outline-none border border-solid w-[110px] border-white bg-transparent rounded-sm text-center font-sans ${isStaticPanel ? 'text-sm' : 'text-base'}`}>
      {speedArray.map((s,i) => <option key={i} value={s}>{s}x Speed</option>)}
    </select>
  );
}
