import React from 'react';

export const CustomAnimationControls: React.FC<any> = ({ isPlaying, isBack, playReverse, pause, playForward, isStaticPanel}) => {
  const activeClass = 'text-[#29ff62]';
  const buttonClass = 'hover:text-[#29ff62]';
  
  return (
    <div className={`flex ${isStaticPanel ? 'gap-x-[5px]' : 'gap-x-[10px]'}`}>
      <button onClick={playReverse} className="btn btn-blank p-0">
        <i className={`bi bi-caret-left-square ${isStaticPanel ? 'text-2xl' : 'text-[30px]'} ${buttonClass} ${isPlaying && isBack ? activeClass : 'text-white'}`}></i></button>
      <button onClick={pause} className="btn btn-blank p-0">
        <i className={`bi bi-pause-btn ${isStaticPanel ? 'text-[28px]' : 'text-[40px]'} ${buttonClass} ${!isPlaying ? activeClass : 'text-white'}`}></i>
      </button>
      <button onClick={playForward} className="btn btn-blank p-0">
        <i className={`bi bi-caret-right-square ${isStaticPanel ? 'text-2xl' : 'text-[30px]'} ${buttonClass} ${isPlaying && !isBack ? activeClass : 'text-white'}`}></i>
      </button>
    </div>
  );
}
