import React, { useEffect, useState } from 'react';
import {
  JulianDate,
} from 'cesium';
import {
  toHourString,
  getTimeRange
} from 'utilities';

export const ClockControl: React.FC<any> = ({ viewerRef, data, options, pause, isStaticPanel, isFinishLoad }) => {
  const [currentTime, setCurrentTime] = useState<string>(toHourString(0));


  useEffect(() => {
    const viewer = viewerRef.current.cesiumElement;
    if (viewer && data.series.length >= 1) {
      const allTimes = getTimeRange(data.series);
      const startTimestamp: Date = allTimes[0] ?? new Date();
      const startTime = JulianDate.fromDate(startTimestamp);

      const updateTime = (clock: any) => {
        if (!isFinishLoad) {
          return;
        }
        const { clockViewModel } = viewerRef?.current?.cesiumElement;
        const timeBySecond = JulianDate.secondsDifference(clock.currentTime, startTime);
        setCurrentTime(toHourString(timeBySecond));
        if (clockViewModel.shouldAnimate && timeBySecond === 0) {
          pause();
        }
      }
      viewer.clock.onTick.addEventListener(updateTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerRef.current?.cesiumElement, data, options, isFinishLoad]);

  return (
    <div className={`flex items-center justify-center text-white py-[10px] font-sans mx-[20px] grow`}>
      {currentTime !== '' && <div className={`${isStaticPanel ? 'text-xl' : 'text-5xl'}`}>{currentTime}</div>}
    </div>
  );
}
