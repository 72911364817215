export interface SimpleOptions {
  assetMode: AssetMode;

  pointSize: number;
  pointColor: string;

  modelScale: number;
  modelMinimumPixelSize: number;
  modelMaximumScale: number;
  modelAssetId: number | null;
  modelAssetUri: string | null;

  trajectoryShow: boolean;
  trajectoryWidth: number;
  trajectoryColor: string;
  trajectoryDashLength: number;

  trajectoryPositionShow: boolean;
  trajectoryPositionSize: number;
  trajectoryPositionColor: string;

  locations: Location[];
  locationPointSize: number;
  locationPointColor: string;

  accessToken: string;

  subscribeToDataHoverEvent: boolean;

  autoComputeOrientation: boolean;
  showAnimation: boolean;
  showTimeline: boolean;
  showInfoBox: boolean;
  showBaseLayerPicker: boolean;
  showSceneModePicker: boolean;
  showProjectionPicker: boolean;
  showCredits: boolean;
  simulationType: string | null;
  panelType: string | null;
  dispersionDistanceUnit: string | null;
}

export enum AssetMode {
  point = 'point',
  model = 'model',
}

export interface Location {
  name: string;
  longitude: number;
  latitude: number;
  altitude: number;
}

export enum SimulationType {
  ThreeDoF = '3DoF',
  SixDoF = '6DoF',
  GNC = 'gnc',
}

export enum PanelType {
  dynamic = 'dynamic',
  static = 'static',
  dispersion = 'dispersion',
}
